import React ,{useState} from "react";
import { useTranslation } from "react-i18next";
import { Link,useNavigate } from "react-router-dom";
import logo from "../assets/images/logo.png";

const FooterSection = () => {
  const { t } = useTranslation();
  const [showImg, setShowImg] = useState(true);
  const navigate = useNavigate();
  return (
    <>
  
    <div class="pay">
      <h2>{t("Payment_Methods")}</h2>
      <ul>
        <li>
          <img src="../assets/images/pay1.webp" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay2.webp" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay3.webp" alt="" />
        </li>
        <li>
          <img src="../assets/images/pay4.webp" alt="" />
        </li>
      
      </ul>
      <div class="payment-inner">
      <div class="payment-row social-row">
          <h2>{t("social_network")}</h2>
          <ul>
            <li>
              <Link to="/">
                {" "}
                <img src="../assets/images/facebook.webp" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src="../assets/images/instagram.webp" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                {" "}
                <img src="../assets/images/youtube.webp" alt="" />
              </Link>
            </li>
            
          </ul>
        </div>
        {/* <div class="payment-row">
          <h2>{t("Responsible_Gaming")}</h2>
          <ul>
            <li>
              <Link to="/">
                <img src="../assets/images/r1.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src="../assets/images/r2.png" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src="../assets/images/r3.png" alt="" />
              </Link>
            </li>
          </ul>
        </div> */}
       
      </div>
      <div class="payment-inner gaming-lic">
        <div class="payment-row">
          <h2>{t("Gaming License")}</h2>
          <ul>
            <li>
              <Link to="/">
                <img src="../assets/images/gaming_license.png" alt="" />
              </Link>
            </li>
            
          </ul>
        </div>
        {/* <div class="payment-row social-row">
          <h2>{t("APP Download")}</h2>
          <ul>
            <li>
              <Link to="/">
                {" "}
                <img src="../assets/images/android-en.png" alt="" />
              </Link>
            </li>
           
            
          </ul>
        </div> */}
      </div>
      <div class="language-sec border-top">
        {/* <h6>
          <Link to="/">{t("About_Us")}</Link>
        </h6> */}
        <h6>
          <Link to="/">{t("Contact_Us")}</Link>
        </h6>
        {/* <h6>
          <Link to="/">{t("Privacy_Policy")}</Link>
        </h6>
        <h6>
          <Link to="/">{t("Terms_Condition")}</Link>
        </h6>
        <h6>
          <Link to="/">{t("Rule_Regulations")}</Link>
        </h6>
        <h6>
          <Link to="/">{t("Responsible_Gaming")}</Link>
        </h6>
        <h6>
          <Link to="/">{t("FAQ")}</Link>
        </h6> */}
        <h6>
          <Link to="/affilate">{t("Affiliate")}</Link>
        </h6>
        <h6>
          <Link to="/">{t("baaji_Blog")}</Link>
        </h6>
      </div>
      {/* <div  class="footer-license">
        <h1  class="license-title">Top Betting Exchange Sites Bangladesh, India &amp; South East Asia</h1>
        <p>A betting exchange is practically an online tool that is designed for gamblers to bet directly against each other and not involve any of
           the traditional bookmakers. Cricket Betting indicates two ways of betting in a cricket match. 
           The first one is to bet on the overall outcome of a live cricket match. The other one is based on 
           the live cricket score of a team in the first 6 overs.<br/>Online betting has developed as a booming 
           industry in South East Asia especially in Bangladesh and India, where the bettors get to choose from an 
           exciting range of Top Betting Exchange Sites in Bangladesh, India and others South East Asia Region.<br/>If you find this interesting 
           and are in search of a reliable and safe Cricket Betting Sites Bangladesh and India, then you should enrol with us. baaji is a reputed 
           online gambling site in the entire Asia. We focus on not only cricket predictions but also other exciting online gaming products.
           </p>
         
           </div> */}
      <div class="border-top footer-bottom">
        <div className="d-flex align-items-center">
          <img src={logo} />
          <div class="link-wrap"><h6>{t("Best_Platform")}</h6> <p>{t("Rights_Reserved")}</p></div>
          
         
        </div>
      </div>
    </div>
    </>
  );
};

export default FooterSection;
